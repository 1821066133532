<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Nombre de la empresa -->
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="empresa"
                type="text"
                label="Empresa"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Seleccion de convenios -->
            <v-col cols="12" class="py-0">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="conveniosSelect"
                label="Convenios"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :items="conveniosItems"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="conveniosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="conveniosSelect.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconConvenios }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ conveniosSelect.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
export default {
  name: "EditRelacionConveniosEmpresas",
  props: {
    editRelacionItem: {
      type: Object,
    },
  },
  data() {
    return {
      formEditTitle: enums.titles.EDIT_RELACION_CONVENIO_EMPRESAS,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      isFormValid: false,
      empresa: "",
      conveniosItems: [],
      conveniosSelect: [],
    };
  },
  async mounted() {
    await this.setSelects();
    if (this.editRelacionItem) this.setRelacion();
  },
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllConvenios() {
      return (
        this.conveniosSelect &&
        this.conveniosSelect.length === this.conveniosItems.length
      );
    },
  },
  methods: {
    ...mapActions({
      saveRelacionConvenioEmpresa:
        "configConvenios/saveRelacionConvenioEmpresa",
      getConvenios: "aportes/fetchConvenios",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
    },
    setRelacion() {
      this.empresa = this.editRelacionItem.entFacNombre;
      if (this.editRelacionItem.obrasSociales !== null)
        this.conveniosSelect = this.editRelacionItem.obrasSociales;
    },
    async saveEdit() {
      this.isFormValid = false;
      const filterIdS = this.conveniosSelect.map((el) => el.id);
      const data = {
        EntFacId: this.editRelacionItem.entFacId,
        OsIds: filterIdS,
      };
      try {
        const res = await this.saveRelacionConvenioEmpresa(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) this.conveniosSelect = [];
        else this.conveniosSelect = this.conveniosItems;
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>